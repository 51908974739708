<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :formatted-columns="formattedColumns"
    authority="Station_Management_Sensor"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'sensors',
        endpoint: 'sensors',
        route: 'sensors',
        title: {
          single: this.$i18n.t('Sensor'),
          plural: this.$i18n.t('Sensors'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'title', label: this.$t('name'), sortable: true },
        { key: 'positionX', label: this.$t('positionX'), sortable: true },
        { key: 'positionY', label: this.$t('positionY'), sortable: true },
        { key: 'ieLevelMeasurement', label: this.$t('ieLevelMeasurement') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [{ key: 'lastParsing', type: 'datetime' }]
    },
    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
        },
        {
          id: 'code',
          type: 'text',
          label: this.$t('code'),
          required: true,
        },
        {
          id: 'positionX',
          type: 'number',
          label: this.$t('Position') + ' X',
          required: true,
        },
        {
          id: 'positionY',
          type: 'number',
          label: this.$t('Position') + ' Y',
          required: true,
        },
        {
          id: 'ieLevelMeasurement',
          type: 'number',
          label: this.$t('ieLevelMeasurement'),
          required: true,
        },
        // {
        //   id: 'bestPoint',
        //   type: 'checkbox',
        //   label: this.$t('bestPoint')
        // },
      ]
    },
  },
}
</script>
